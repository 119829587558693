dmx.Component('stripe-payment-method-messaging', {
  // for the payment methods KLARNA, AFTERPAY and AFFIRM
  attributes: {
    amount: {
      type: Number,
      default: null,
      required: true,
    },

    currency: {
      type: String,
      default: null,
      required: true,
      enum: ['USD', 'GBP', 'EUR', 'DKK', 'NOK', 'SEK', 'CAD', 'AUD'],
      enum_labels: ['US Dollar', 'British Pound', 'Euro', 'Danish Krone', 'Norwegian Krone', 'Swedish Krona', 'Canadian Dollar', 'Australian Dollar']
    },

    countryCode: {
      type: String,
      default: null,
      required: true,
      enum: ['US', 'CA', 'AU', 'NZ', 'GB', 'IE', 'FR', 'ES', 'DE', 'AT', 'BE', 'DK', 'FI', 'IT', 'NL', 'NO', 'SE'],
      enum_labels: ['United States', 'Canada', 'Australia', 'New Zealand', 'United Kingdom', 'Ireland', 'France', 'Spain', 'Germany', 'Austria', 'Belgium', 'Denmark', 'Finland', 'Italy', 'Netherlands', 'Norway', 'Sweden']
    },
  },

  render (node) {
    dmx.stripe.ready(() => {
      this._element = dmx.stripe.elements.create('paymentMethodMessaging', {
        amount: this.props.amount,
        currency: this.props.currency,
        countryCode: this.props.countryCode,
      });

      this._element.mount(node);
    });
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

});