dmx.Component('stripe-card-expiry', {

  ui: {
    deprecated: true,
  },

  initialData: {
    complete: false,
    empty: true,
    error: undefined,
  },

  attributes: {
    placeholder: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hideErrors: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    blur () {
      this._element.blur();
    },

    clear () {
      this._element.clear();
    },

    focus () {
      this._element.focus();
    },
  },

  events: {
    change: Event,
    ready: Event,
    focus: Event,
    blur: Event,
    escape: Event,
  },

  render (node) {
    dmx.stripe.ready(() => {
      let theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

      this._element = dmx.stripe.elements.create('cardExpiry', {
        placeholder: this.props.placeholder,
        disabled: this.props.disabled,
        classes: theme.classes,
        style: theme.style,
      });

      this._element.mount(node);

      this._errorElement = document.createElement('div');
      this._errorElement.className = theme.error.class;

      node.insertAdjacentElement('afterend', this._errorElement);

      this._element.on('change', this._changeHandler.bind(this));
      this._element.on('change', this.dispatchEvent.bind(this, 'change'));
      this._element.on('ready', this.dispatchEvent.bind(this, 'ready'));
      this._element.on('focus', this.dispatchEvent.bind(this, 'focus'));
      this._element.on('blur', this.dispatchEvent.bind(this, 'blur'));
      this._element.on('escape', this.dispatchEvent.bind(this, 'escape'));
    });
  },

  performUpdate (updatedProps) {
    if (this._element) {
      if (updatedProps.has('placeholder')) {
        this._element.update({ placeholder: this.props.placeholder });
      }

      if (updatedProps.has('disabled')) {
        this._element.update({ disabled: this.props.disabled });
      }
    }
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

  _changeHandler (event) {
    this.set(event);

    if (!this.props.hideErrors) {
      this._errorElement.textContent = event.error ? event.error.message : '';
    }
  },

  onchange: function (event) {
    this.set(event);

    if (!this.props['hide-errors']) {
      this.errorElement.textContent = event.error ? event.error.message : '';
    }
  },

});
