dmx.Component('stripe-ideal-bank', {

  ui: {
    deprecated: true,
  },

  initialData: {
    complete: false,
    empty: true,
    value: '',
    error: undefined,
    source: undefined,
    paymentMethod: undefined,
    paymentIntent: undefined,
    setupIntent: undefined,
  },

  attributes: {
    value: {
      type: String,
      default: '',
    },

    hideIcon: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    returnUrl: {
      type: String,
      default: window.location.href,
    },

    hideErrors: {
      type: Boolean,
      default: false,
    },

    accountholderName: {
      type: String,
      default: '',
    },
  },

  methods: {
    blur () {
      if (this._element) {
        this._element.blur();
      }
    },

    clear () {
      if (this._element) {
        this._element.clear();
      }
    },

    focus () {
      if (this._element) {
        this._element.focus();
      }
    },

    createSource (data) {
      if (this._element) {
        return dmx.stripe.instance.createSource(this._element, data).then((result) => {
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          } else {
            this.set('source', result.source);
            this.dispatchEvent('source_created');
          }
          return result;
        });
      }
    },

    createPaymentMethod () {
      if (this._element) {
        return dmx.stripe.instance.createPaymentMethod({
          type: 'ideal',
          ideal: this._element,
        }).then((result) => {
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          } else {
            this.set('paymentMethod', result.paymentMethod);
            this.dispatchEvent('payment_method_created');
          }
          return result;
        });
      }
    },

    confirmPayment (clientSecret) {
      if (this._element) {
        return dmx.stripe.instance.confirmIdealPayment(clientSecret || dmx.stripe.client_secret, {
          payment_method: {
            ideal: this._element,
            billing_details: {
              name: this.props.accountholderName,
            },
          },
          return_url: this.props.returnUrl,
        }).then((result) => {
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          } else {
            this.set('paymentIntent', result.paymentIntent);
            this.dispatchEvent('payment_confirmed');
          }
          return result;
        });
      }
    },

    confirmSetup (clientSecret) {
      if (this._element) {
        return dmx.stripe.instance.confirmIdealSetup(clientSecret || dmx.stripe.client_secret, {
          payment_method: {
            ideal: this._element,
            billing_details: {
              name: this.props.accountholderName,
            },
          },
        }).then((result) => {
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          } else {
            this.set('setupIntent', result.setupIntent);
            this.dispatchEvent('setup_confirmed');
          }
          return result;
        });
      }
    },
  },

  events: {
    change: Event,
    ready: Event,
    focus: Event,
    blur: Event,
    escape: Event,
    error: Event,
    source_created: Event,
    payment_method_create: Event,
    payment_confirmed: Event,
    setup_confirmed: Event,
  },

  render (node) {
    dmx.stripe.ready(() => {
      const theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

      this._element = dmx.stripe.elements.create('idealBank', {
        value: this.props.value,
        hideIcon: this.props.hideIcon,
        disabled: this.props.disabled,
        classes: theme.classes,
        style: theme.style,
      });

      this._element.mount(node);

      this._errorElement = document.createElement('div');
      this._errorElement.className = theme.error.class;

      node.insertAdjacentElement('afterend', this._errorElement);

      this._element.on('change', this._changeHandler.bind(this));
      this._element.on('change', this.dispatchEvent.bind(this, 'change'));
      this._element.on('ready', this.dispatchEvent.bind(this, 'ready'));
      this._element.on('focus', this.dispatchEvent.bind(this, 'focus'));
      this._element.on('blur', this.dispatchEvent.bind(this, 'blur'));
      this._element.on('escape', this.dispatchEvent.bind(this, 'escape'));
    });
  },

  performUpdate (updatedProps) {
    if (this._element) {
      if (updatedProps.has('value')) {
        this._element.update({ value: this.props.value });
      }

      if (updatedProps.has('hideIcon')) {
        this._element.update({ hideIcon: this.props.hideIcon });
      }

      if (updatedProps.has('disabled')) {
        this._element.update({ disabled: this.props.disabled });
      }
    }
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

  _changeHandler (event) {
    this.set(event);

    if (!this.props.hideErrors) {
      this._errorElement.textContent = event.error ? event.error.message : '';
    }
  },

});
