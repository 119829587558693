dmx.Component('stripe-elements', {

  initialData: {
    ready: false,
    error: null,
  },

  attributes: {
    key: {
      type: String,
      default: null,
    },

    account: {
      type: String,
      default: null,
    },

    locale: {
      type: String,
      default: 'auto',
      enum: ['auto', 'ar', 'bg', 'ca', 'cs', 'da', 'de', 'el', 'en', 'en-GB', 'es', 'es-419', 'et', 'fi', 'fr', 'fr-CA', 'he', 'hi', 'hr', 'hu', 'id', 'it', 'ja', 'ko', 'lt', 'lv', 'ms', 'mt', 'nb', 'nl', 'pl', 'pt', 'pt-BR', 'ro', 'ru', 'sk', 'sl', 'sv', 'th', 'tr', 'uk', 'vi', 'zh', 'zh-HK', 'zh-TW'],
      enum_labels: ['Auto', 'Arabic', 'Bulgarian', 'Catalan', 'Czech', 'Danish', 'German', 'Greek', 'English', 'English (UK)', 'Spanish', 'Spanish (Latin America)', 'Estonian', 'Finnish', 'French', 'French (Canada)', 'Hebrew', 'Hindi', 'Croatian', 'Hungarian', 'Indonesian', 'Italian', 'Japanese', 'Korean', 'Lithuanian', 'Latvian', 'Malay', 'Maltese', 'Norwegian', 'Dutch', 'Polish', 'Portuguese', 'Portuguese (Brazil)', 'Romanian', 'Russian', 'Slovak', 'Slovenian', 'Swedish', 'Thai', 'Turkish', 'Ukrainian', 'Vietnamese', 'Chinese', 'Chinese (Hong Kong)', 'Chinese (Taiwan)'],
    },

    loader: {
      type: String,
      default: 'auto',
      enum: ['auto', 'always', 'never'],
    },

    flow: {
      type: String,
      default: 'withoutIntent',
      enum: ['none', 'withIntent', 'withoutIntent'],
      ui: {
        help: 'The flow determines how the payment is processed. If you are using an Intent, you should use the withIntent flow. If you want to collect payment details before creating an Intent, you should use the withoutIntent flow.',
      }
    },

    // Elements Appearance API
    // https://docs.stripe.com/elements/appearance-api

    // TODO: have a custom bootstrap theme?

    appearanceTheme: {
      type: String,
      default: 'stripe',
      enum: ['stripe', 'night', 'flat', 'bootstrap'],
    },

    appearanceVariables: {
      type: Object,
      default: {},
      ui: {
        isDynamic: true
      }
    },

    appearanceRules: {
      type: Object,
      default: {},
      ui: {
        isDynamic: true
      }
    },

    // required with intent

    clientSecret: {
      type: String,
      default: null,
      depends_of: { name: 'flow', value: 'withIntent' },
    },

    // required without an intent and Express Checkout Element

    mode: {
      type: String,
      default: null,
      enum: ['payment', 'setup', 'subscription'],
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },

    amount: {
      type: Number,
      default: null,
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },

    currency: {
      type: String,
      default: null,
      //specify the currency in lowercase
      enum: ['usd', 'aed', 'afn', 'all', 'amd', 'ang', 'aoa', 'ars', 'aud', 'awg', 'azn', 'bam', 'bbd', 'bdt', 'bgn', 'bif', 'bmd', 'bnd', 'bob', 'brl', 'bsd', 'bwp', 'byn', 'bzd', 'cad', 'cdf', 'chf', 'clp', 'cny', 'cop', 'crc', 'cve', 'czk', 'djf', 'dkk', 'dop', 'dzd', 'egp', 'etb', 'eur', 'fjd', 'fkp', 'gbp', 'gel', 'gip', 'gmd', 'gnf', 'gtq', 'gyd', 'hkd', 'hnl', 'htg', 'huf', 'idr', 'ils', 'inr', 'isk', 'jmd', 'jpy', 'kes', 'kgs', 'khr', 'kmf', 'krw', 'kyd', 'kzt', 'lak', 'lbp', 'lkr', 'lrd', 'lsl', 'mad', 'mdl', 'mga', 'mkd', 'mmk', 'mnt', 'mop', 'mur', 'mvr', 'mwk', 'mxn', 'myr', 'mzn', 'nad', 'ngn', 'nio', 'nok', 'npr', 'nzd', 'pab', 'pen', 'pgk', 'php', 'pkr', 'pln', 'pyg', 'qar', 'ron', 'rsd', 'rub', 'rwf', 'sar', 'sbd', 'scr', 'sek', 'sgd', 'shp', 'sle', 'sos', 'srd', 'std', 'szl', 'thb', 'tjs', 'top', 'try', 'ttd', 'twd', 'tzs', 'uah', 'ugx', 'uyu', 'uzs', 'vnd', 'vuv', 'wst', 'xaf', 'xcd', 'xof', 'xpf', 'yer', 'zar', 'zmw'],
      enum_labels: ['US Dollar', 'United Arab Emirates Dirham', 'Afghan Afghani', 'Albanian Lek', 'Armenian Dram', 'Netherlands Antillean Guilder', 'Angolan Kwanza', 'Argentine Peso', 'Australian Dollar', 'Aruban Florin', 'Azerbaijani Manat', 'Bosnia-Herzegovina Convertible Mark', 'Barbadian Dollar', 'Bangladeshi Taka', 'Bulgarian Lev', 'Burundian Franc', 'Bermudian Dollar', 'Brunei Dollar', 'Bolivian Boliviano', 'Brazilian Real', 'Bahamian Dollar', 'Botswanan Pula', 'Belarusian Ruble', 'Belize Dollar', 'Canadian Dollar', 'Congolese Franc', 'Swiss Franc', 'Chilean Peso', 'Chinese Yuan', 'Colombian Peso', 'Costa Rican Colón', 'Cape Verdean Escudo', 'Czech Republic Koruna', 'Djiboutian Franc', 'Danish Krone', 'Dominican Peso', 'Algerian Dinar', 'Egyptian Pound', 'Ethiopian Birr', 'Euro', 'Fijian Dollar', 'Falkland Islands Pound', 'British Pound Sterling', 'Georgian Lari', 'Gibraltar Pound', 'Gambian Dalasi', 'Guinean Franc', 'Guatemalan Quetzal', 'Guyanaese Dollar', 'Hong Kong Dollar', 'Honduran Lempira', 'Haitian Gourde', 'Hungarian Forint', 'Indonesian Rupiah', 'Israeli New Sheqel', 'Indian Rupee', 'Icelandic Króna', 'Jamaican Dollar', 'Japanese Yen', 'Kenyan Shilling', 'Kyrgystani Som', 'Cambodian Riel', 'Comorian Franc', 'South Korean Won', 'Cayman Islands Dollar', 'Kazakhstani Tenge', 'Laotian Kip', 'Lebanese Pound', 'Sri Lankan Rupee', 'Liberian Dollar', 'Lesotho Loti', 'Moroccan Dirham', 'Moldovan Leu', 'Malagasy Ariary', 'Macedonian Denar', 'Myanma Kyat', 'Mongolian Tugrik', 'Macanese Pataca', 'Mauritian Rupee', 'Maldivian Rufiyaa',
      'Malawian Kwatcha', 'Mexican Peso', 'Malaysian Ringgit', 'Mozambican Metical', 'Namibian Dollar', 'Nigerian Naira', 'Nicaraguan Córdoba', 'Norwegian Krone', 'Nepalese Rupee', 'New Zealand Dollar', 'Panamanian Balboa', 'Peruvian Nuevo Sol', 'Papua New Guinean Kina', 'Philippine Peso', 'Pakistani Rupee', 'Polish Zloty', 'Paraguayan Guarani', 'Qatari Rial', 'Romanian Leu', 'Serbian Dinar', 'Russian Ruble', 'Rwandan Franc', 'Saudi Riyal', 'Solomon Islands Dollar', 'Seychellois Rupee', 'Swedish Krona', 'Singapore Dollar', 'Saint Helena Pound', 'Sierra Leonean Leone', 'Somali Shilling', 'Surinamese Dollar', 'São Tomé and Príncipe Dobra', 'Swazi Lilangeni', 'Thai Baht', 'Tajikistani Somoni', 'Tongan Paʻanga', 'Turkish Lira', 'Trinidad and Tobago Dollar', 'New Taiwan Dollar', 'Tanzanian Shilling', 'Ukrainian Hryvnia', 'Ugandan Shilling', 'Uruguayan Peso', 'Uzbekistan Som', 'Vietnamese Dong', 'Vanuatu Vatu', 'Samoan Tala', 'CFA Franc BEAC', 'East Caribbean Dollar', 'CFA Franc BCEAO', 'CFP Franc', 'Yemeni Rial', 'South African Rand', 'Zambian Kwacha'],
    },

    // following are optional (not available with intent)
    // the options should match the options of the Stripe API server-side

    setupFutureUsage: {
      type: String,
      default: null,
      enum: ['on_session', 'off_session'],
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },

    captureMethod: {
      type: String,
      default: null,
      enum: ['automatic', 'automatic_async', 'manual'],
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },

    onBehalfOf: { // Connect only
      type: String,
      default: null,
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },

    paymentMethodTypes: {
      type: Array,
      default: null,
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },

    paymentMethodConfiguration: {
      type: String,
      default: null,
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },

    paymentMethodOptions: {
      type: Object,
      default: null,
      depends_of: { name: 'flow', value: 'withoutIntent' },
    },
  },

  methods: {
    // use this when using intents to getch updates
    fetchUpdates () {
      return this._element.fetchUpdates().then((result) => {
        if (result.error) {
          this.set('error', result.error);
          this.dispatchEvent('error');
        }
      });
    },

    // use when not using intents to validate the form
    submit () {
      return this._element.submit().then((result) => {
        if (result.error) {
          this.set('error', result.error);
          this.dispatchEvent('error');
        }
      });
    },
  },

  events: {
    ready: Event,
    error: Event,
  },

  render: false,

  init () {
    this._setBootstrapVariables = this._setBootstrapVariables.bind(this);
    this._init();
  },

  performUpdate (updatedProps) {
    if (!this._elements) {
      return this._init();
    }

    if (updatedProps.has('locale')) {
      this._elements.update({ locale: this.props.locale });
    }

    if (updatedProps.has('mode')) {
      this._elements.update({ mode: this.props.mode });
    }

    if (updatedProps.has('currency')) {
      this._elements.update({ currency: this.props.currency });
    }

    if (updatedProps.has('amount')) {
      this._elements.update({ amount: this.props.amount });
    }

    if (updatedProps.has('setupFutureUsage')) {
      this._elements.update({ setupFutureUsage: this.props.setupFutureUsage });
    }

    if (updatedProps.has('captureMethod')) {
      this._elements.update({ captureMethod: this.props.captureMethod });
    }

    if (updatedProps.has('onBehalfOf')) {
      this._elements.update({ onBehalfOf: this.props.onBehalfOf });
    }

    if (updatedProps.has('paymentMethodTypes')) {
      this._elements.update({ paymentMethodTypes: this.props.paymentMethodTypes });
    }

    if (updatedProps.has('paymentMethodOptions')) {
      this._elements.update({ paymentMethodOptions: this.props.paymentMethodOptions });
    }

    if (updatedProps.has('appearanceTheme') || updatedProps.has('appearanceVariables') || updatedProps.has('appearanceRules')) {
      this._elements.update({
        appearance: {
          theme: this.props.appearanceTheme,
          variables: this.props.appearanceVariables,
          rules: this.props.appearanceRules,
        }
      });
    }
  },

  destroy () {
    if (this._observer) {
      this._observer.disconnect();
      this._observer = null;
    }

    if (this._mediaQuery) {
      this._mediaQuery.removeEventListener('change', this._setBootstrapVariables);
      this._mediaQuery = null;
    }
  },

  _init () {
    switch (this.props.flow) {
      case 'withIntent':
        if (this.props.clientSecret) this._create();
        break;
      case 'withoutIntent':
        if (this.props.mode && this.props.amount && this.props.currency) this._create();
        break;
      default:
        this._create();
    }
  },

  _setBootstrapVariables () {
    const getCSSValue = (name) => getComputedStyle(document.body).getPropertyValue(name);

    this.props.appearanceVariables = {
      fontFamily: getCSSValue('--bs-body-font-family'),
      fontSizeBase: getCSSValue('--bs-body-font-size'),
      borderRadius: getCSSValue('--bs-border-radius'),
      colorPrimary: getCSSValue('--bs-primary'),
      colorBackground: getCSSValue('--bs-body-bg'),
      colorText: getCSSValue('--bs-body-color'),
      colorDanger: getCSSValue('--bs-danger'),
      fontLineHeight: getCSSValue('--bs-body-line-height'),
      colorSuccess: getCSSValue('--bs-success'),
      colorWarning: getCSSValue('--bs-warning'),
      colorTextSecondary: getCSSValue('--bs-secondary-color'),
      colorTextPlaceholder: getCSSValue('--bs-secondary-color'),
    };
  },

  _create () {
    let stripeOptions = {
      locale: this.props.locale,
    };

    let themeVariables = {};

    if (this.props.appearanceTheme == 'bootstrap') {
      this.destroy()
      this._mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      this._mediaQuery.addEventListener('change', this._setBootstrapVariables);
      this._observer = new MutationObserver(this._setBootstrapVariables);
      this._observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-bs-theme'] });
      this._setBootstrapVariables();
    }

    let elementsOptions = {
      appearance: {
        theme: this.props.appearanceTheme,
        variables: this.props.appearanceVariables,
        rules: this.props.appearanceRules,
      },
      loader: this.props.loader,
      currency: this.props.currency,
    };

    if (this.props.account) stripeOptions.stripeAccount = this.props.account;

    if (this.props.clientSecret) elementsOptions.clientSecret = this.props.clientSecret;
    if (this.props.mode) elementsOptions.mode = this.props.mode;
    if (this.props.currency) elementsOptions.currency = this.props.currency;
    if (this.props.amount) elementsOptions.amount = this.props.amount;
    if (this.props.setupFutureUsage) elementsOptions.setupFutureUsage = this.props.setupFutureUsage;
    if (this.props.captureMethod) elementsOptions.captureMethod = this.props.captureMethod;
    if (this.props.onBehalfOf) elementsOptions.onBehalfOf = this.props.onBehalfOf;
    if (this.props.paymentMethodTypes) elementsOptions.paymentMethodTypes = this.props.paymentMethodTypes;
    if (this.props.paymentMethodConfiguration) elementsOptions.paymentMethodConfiguration = this.props.paymentMethodConfiguration;
    if (this.props.paymentMethodOptions) elementsOptions.paymentMethodOptions = this.props.paymentMethodOptions;

    this._stripe = Stripe(this.props.key, stripeOptions);
    this._elements = this._stripe.elements(elementsOptions);

    dmx.stripe.instance = this._stripe;
    dmx.stripe.elements = this._elements;

    if (dmx.stripe.wait.size) {
      for (const cb of dmx.stripe.wait) {
        cb(dmx.stripe.instance);
      }
      dmx.stripe.wait.clear();
    }

    this.set('ready', true);
    this.dispatchEvent('ready');
  },

});