dmx.Component('stripe-card-number', {

  ui: {
    deprecated: true,
  },

  initialData: {
    complete: false,
    brand: '',
    empty: true,
    error: undefined,
    paymentMethod: undefined,
    paymentIntent: undefined,
    setupIntent: undefined,
  },

  attributes: {
    placeholder: {
      type: String,
      default: '',
    },

    iconStyle: {
      type: String,
      default: 'default',
      enum: ['default', 'solid'],
    },

    showIcon: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hideErrors: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    blur () {
      this._element.blur();
    },

    clear () {
      this._element.clear();
    },

    focus () {
      this._element.focus();
    },

    createPaymentMethod () {
      if (this._element) {
        return dmx.stripe.instance.createPaymentMethod({
          type: 'card',
          card: this._element,
        }).then(result => {
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          } else {
            this.set('paymentMethod', result.paymentMethod);
            this.dispatchEvent('payment_method_created');
          }
          return result;
        });
      }
    },

    confirmPayment (clientSecret) {
      if (this._element) {
        return dmx.stripe.instance.confirmCardPayment(clientSecret || dmx.stripe.client_secret, {
          payment_method: { card: this._element },
        }).then(result => {
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          } else {
            this.set('paymentIntent', result.paymentIntent);
            this.dispatchEvent('payment_confirmed');
          }
          return result;
        });
      }
    },

    confirmSetup (clientSecret) {
      if (this._element) {
        return dmx.stripe.instance.confirmCardSetup(clientSecret || dmx.stripe.client_secret, {
          payment_method: { card: this._element },
        }).then(result => {
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          } else {
            this.set('setupIntent', result.setupIntent);
            this.dispatchEvent('setup_confirmed');
          }
          return result;
        });
      }
    },
  },

  events: {
    change: Event,
    ready: Event,
    focus: Event,
    blur: Event,
    escape: Event,
  },

  render (node) {
    dmx.stripe.ready(() => {
      let theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

      this._element = dmx.stripe.elements.create('cardNumber', {
        placeholder: this.props.placeholder,
        iconStyle: this.props.iconStyle,
        showIcon: this.props.showIcon,
        disabled: this.props.disabled,
        classes: theme.classes,
        style: theme.style,
      });

      this._element.mount(node);

      this._errorElement = document.createElement('div');
      this._errorElement.className = theme.error.class;

      node.insertAdjacentElement('afterend', this._errorElement);

      this._element.on('change', this._changeHandler.bind(this));
      this._element.on('change', this.dispatchEvent.bind(this, 'change'));
      this._element.on('ready', this.dispatchEvent.bind(this, 'ready'));
      this._element.on('focus', this.dispatchEvent.bind(this, 'focus'));
      this._element.on('blur', this.dispatchEvent.bind(this, 'blur'));
      this._element.on('escape', this.dispatchEvent.bind(this, 'escape'));
    });
  },

  performUpdate (updatedProps) {
    if (this._element) {
      if (updatedProps.has('placeholder')) {
        this._element.update({ placeholder: this.props.placeholder });
      }

      if (updatedProps.has('iconStyle')) {
        this._element.update({ iconStyle: this.props.iconStyle });
      }

      if (updatedProps.has('showIcon')) {
        this._element.update({ showIcon: this.props.showIcon });
      }

      if (updatedProps.has('disabled')) {
        this._element.update({ disabled: this.props.disabled });
      }
    }
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

  _changeHandler (event) {
    this.set(event);

    if (!this.prop.hideErrors) {
      this._errorElement.textContent = event.error ? event.error.message : '';
    }
  },

});
