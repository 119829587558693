dmx.getBootstrapVariable = function(name, defaultValue = null) {
  return getComputedStyle(document.body).getPropertyValue('--bs-' + name).trim() || defaultValue;
};

dmx.stripe = {
  wait: new Set(),
  locale: 'auto',
  instance: null,
  elements: null,
  ready: function(cb) {
    if (dmx.stripe.instance) {
      cb(dmx.stripe.instance);
    } else {
      dmx.stripe.wait.add(cb);
    }
  },
  get theme () {
    return {
      default: {
        classes: {},
        style: {},
        error: {
          class: 'dmxValidator-invalid'
        }
      },
      bootstrap: {
        classes: {
          base: 'form-control',
          invalid: 'is-invalid',
          // bootstrap has no focus class
        },
        style: {
          base: {
            color: dmx.getBootstrapVariable('body-color', '#212529'),
            fontFamily: 'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            fontSize: '16px',
            iconColor: dmx.getBootstrapVariable('body-color', '#212529'),
            lineHeight: '26px',
            '::placeholder': {
              color: dmx.getBootstrapVariable('secondary-color', 'rgba(222, 226, 230, 0.75)')
            },
          },
        },
        error: {
          class: 'invalid-feedback'
        }
      }
    };
  }
};