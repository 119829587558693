// TODO: make up-to-date with latest Stripe API and App Connect 2

dmx.Component('stripe-checkout-form', {

  ui: {
    deprecated: true,
  },

  extends: 'form',

  initialData: {
    error: null,
    processing: false,
    paymentIntent: null,
    paymentMethodType: 'card',
  },

  attributes: {
    credentials: {
      type: Boolean,
      default: false,
    },

    autosubmit: {
      type: Boolean,
      default: false,
    },

    timeout: {
      type: Number,
      default: 0,
    },

    paymentMethodType: {
      type: String,
      default: 'card',
      enum: [
        'afterpay_clearpay',
        'alipay',
        'au_becs_debit',
        'bancontact',
        'card',
        'eps',
        'fpx',
        'giropay',
        'grabpay',
        'ideal',
        'oxxo',
        'p24',
        'sepa_debit',
        'sofort',
      ],
    },

    propClientSecret: {
      type: String,
      default: 'client_secret',
    },

    successUrl: {
      type: String,
      default: null,
    },
  },

  events: {
    error: Event,
    success: Event,
  },

  render (node) {
    dmx.Component('form').prototype.render.call(this, node);

    this.set('paymentMethodType', this.props.paymentMethodType);

    if (this.props.autosubmit) {
      dmx.nextTick(() => {
        this.submit();
      });
    }
  },

  performUpdate (updatedProps) {
    dmx.Component('form').prototype.performUpdate.call(this, updatedProps);

    if (updatedProps.has('paymentMethodType')) {
      this.set('paymentMethodType', this.props.paymentMethodType);
    }
  },

  _submit () {
    if (this.data.processing) return;

    this.set('error', '');
    this.set('processing', true);
    this._updateErrorDiv('');

    var formData = new FormData(this.$node);

    if (this.$node.dmxExtraData) {
      Object.keys(this.$node.dmxExtraData).forEach(function (key) {
        var value = this.$node.dmxExtraData[key];

        if (Array.isArray(value)) {
          if (!/\[\]$/.test(key)) {
            key += '[]';
          }
          value.forEach(function (val) {
            formData.append(key, val);
          });
        } else {
          formData.set(key, value);
        }
      }, this);
    }

    var xhr = new XMLHttpRequest();

    xhr.open('POST', this.$node.action);

    xhr.timeout = this.props.timeout * 1000;
    xhr.withCredentials = this.props.credentials;

    xhr.onload = this.onload.bind(this, xhr);
    xhr.onabort = this.onabort.bind(this);
    xhr.onerror = this.onerror.bind(this);
    xhr.ontimeout = this.ontimeout.bind(this);

    try {
      xhr.send(formData);
    } catch (error) {
      this._error(error);
    }
  },

  _error: function (error) {
    this.set('error', error.message);
    this.set('processing', false);
    this.dispatchEvent('error');
    this._updateErrorDiv(error.message);
  },

  _updateErrorDiv: function (message) {
    // Show error in form
    var errorDiv = document.getElementById('dmxValidatorError' + this.name);

    if (!errorDiv) {
      errorDiv = document.createElement('div');
      errorDiv.id = 'dmxValidatorError' + this.name;
      errorDiv.className = 'dmxValidator-error invalid-feedback';
      this.$node.append(errorDiv);
    }

    errorDiv.textContent = message;
  },

  _success: function (paymentIntent) {
    this.set('paymentIntent', paymentIntent);
    this.set('processing', false);
    this.dispatchEvent('success');

    if (this.props['success-url']) {
      document.location.replace(this.props['success-url']);
    }
  },

  _handleResult: function (result) {
    if (result.error) {
      this._error(result.error);
    } else {
      this._success(result.paymentIntent);
    }
  },

  onload: function (xhr) {
    try {
      // Check for payment method control and get selected payment method type from there (ignoring any binding)
      // Caution: using internal methods that could change in the future
      var self = this,
        found = false;

      // first check the direct children
      this.children.forEach(function (child) {
        if (child instanceof dmx.Component('stripe-payment-method')) {
          self.props['payment-method-type'] = child.data.selected;
          found = true;
        }
      });

      if (!found) {
        // if not found as child look in the dom
        var node = this.$node.querySelector('dmx-stripe-payment-method, [is=dmx-stripe-payment-method]');
        if (node && node.dmxComponent) {
          this.props['payment-method-type'] = node.dmxComponent.data.selected;
        }
      }
    } catch (error) {
      console.error(error);
    }

    try {
      var response = JSON.parse(xhr.responseText);

      if (xhr.status == 200) {
        var clientSecret = response[this.props['prop-client-secret']];

        if (!clientSecret) {
          throw new Error(response.error || response.responseText);
        }

        switch (this.props['payment-method-type']) {
          case 'card':
            dmx.stripe.instance
              .confirmCardPayment(clientSecret, {
                payment_method: {
                  card: dmx.stripe.elements.getElement('card') || dmx.stripe.elements.getElement('cardNumber'),
                },
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'afterpay_clearpay':
            dmx.stripe.instance
              .confirmAfterpayClearpayPayment(clientSecret, {
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'alipay':
            dmx.stripe.instance
              .confirmAlipayPayment(clientSecret, {
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'au_becs_debit':
            dmx.stripe.instance
              .confirmAuBecsDebitPayment(clientSecret, {
                payment_method: { au_becs_debit: dmx.stripe.elements.getElement('auBankAccount') },
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'bancontact':
            dmx.stripe.instance
              .confirmBancontactPayment(clientSecret, {
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'eps':
            dmx.stripe.instance
              .confirmEpsPayment(clientSecret, {
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'fpx':
            dmx.stripe.instance
              .confirmFpxPayment(clientSecret, {
                payment_method: { fpx: dmx.stripe.elements.getElement('fpxBank') },
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'giropay':
            dmx.stripe.instance
              .confirmGiropayPayment(clientSecret, {
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'grabpay':
            dmx.stripe.instance
              .confirmGrabPayPayment(clientSecret, {
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'ideal':
            dmx.stripe.instance
              .confirmIdealPayment(clientSecret, {
                payment_method: { ideal: dmx.stripe.elements.getElement('idealBank') },
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'oxxo':
            dmx.stripe.instance
              .confirmOxxoPayment(clientSecret)
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'p24':
            dmx.stripe.instance
              .confirmP24Payment(clientSecret, {
                payment_method: { p24: dmx.stripe.elements.getElement('p24Bank') },
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'sepa_debit':
            dmx.stripe.instance
              .confirmSepaDebitPayment(clientSecret, {
                payment_method: { p24: dmx.stripe.elements.getElement('iban') },
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;

          case 'sofort':
            dmx.stripe.instance
              .confirmSofortPayment(clientSecret, {
                return_url: this.props['success-url'],
              })
              .then(this._handleResult.bind(this))
              .catch(this._error.bind(this));
            break;
        }
      } else {
        this._error({ message: 'Request returned with status ' + xhr.status + '.' });
      }
    } catch (error) {
      this._error(error);
    }
  },

  onabort: function () {
    this._error({ message: 'The request was aborted.' });
  },

  onerror: function (error) {
    this._error(error);
  },

  ontimeout: function () {
    this._error({ message: 'The request timed out.' });
  },

});
