dmx.Actions({

  'stripe.confirmPayment': function (options) {
    /* @@start_params [
      {name: 'clientSecret', optionName: 'clientSecret', title: 'Client Secret', type: 'text', serverDataBindings: true},
      {name: 'returnUrl', optionName: 'confirmParams.return_url', title: 'Return URL', type: 'file', required: true, serverDataBindings: true, routePicker: true},
    ] @@end_params
    */
    if (!options) options = {};
    if (!options.confirmParams || !options.confirmParams.return_url) throw Error('confirmParams.return_url is required');
    options.elements = dmx.stripe.elements;
    if (options.confirmParams.return_url.startsWith('/')) {
      options.confirmParams.return_url = location.origin + options.confirmParams.return_url;
    }
    return dmx.stripe.instance.confirmPayment(options);
  },

  'stripe.confirmSetup': function (options) {
    /* @@start_params [
        {name: 'clientSecret', optionName: 'clientSecret', title: 'Client Secret', type: 'text', serverDataBindings: true},
        {name: 'returnUrl', optionName: 'confirmParams.return_url', title: 'Return URL', type: 'file', required: true, serverDataBindings: true, routePicker: true},
      ] @@end_params
      */
    if (!options) options = {};
    if (!options.confirmParams || !options.confirmParams.return_url) throw Error('confirmParams.return_url is required');
    options.elements = dmx.stripe.elements;
    if (options.confirmParams.return_url.startsWith('/')) {
      options.confirmParams.return_url = location.origin + options.confirmParams.return_url;
    }
    return dmx.stripe.instance.confirmSetup(options);
  },

  'stripe.retrievePaymentIntent': function (options) {
    /* @@start_params [
      {name: 'clientSecret', optionName: 'clientSecret', title: 'Client Secret', type: 'text', required: true, serverDataBindings: true},
    ] @@end_params
    */
    if (!options) options = {};
    if (!options.clientSecret) throw Error('clientSecret is required');
    return dmx.stripe.instance.retrievePaymentIntent(options.clientSecret);
  },

  'stripe.retrieveSetupIntent': function (options) {
    /* @@start_params [
      {name: 'clientSecret', optionName: 'clientSecret', title: 'Client Secret', type: 'text', required: true, serverDataBindings: true},
    ] @@end_params
    */
    if (!options) options = {};
    if (!options.clientSecret) throw Error('clientSecret is required');
    return dmx.stripe.instance.retrieveSetupIntent(options.clientSecret);
  },

});
