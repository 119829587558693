dmx.Component('stripe-payment-request-button', {

  ui: {
    deprecated: true,
  },

  initialData: {
    token: null,
    source: null,
    paymentMethod: null,
    shippingAddress: null,
    shippingOption: null,
  },

  attributes: {
    country: {
      type: String,
      default: '',
    },

    currency: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    amount: {
      type: Number,
      default: null,
    },

    pending: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: null,
    },

    requestName: {
      type: Boolean,
      default: false,
    },

    requestEmail: {
      type: Boolean,
      default: false,
    },

    requestPhone: {
      type: Boolean,
      default: false,
    },

    requestShipping: {
      type: Boolean,
      default: false,
    },

    shippingOptions: {
      type: Array,
      default: null,
    },
  },

  methods: {
    complete () {
      if (this._complete) {
        this._complete();
      }
    },

    updateWith (details) {
      if (this._updateWith) {
        this._updateWith(details);
      }
    },
  },

  events: {
    click: Event,
    token: Event,
    source: Event,
    paymentmethod: Event,
    shippingoptionchange: Event,
  },

  render (node) {
    dmx.stripe.ready(() => {
      let theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

      this._paymentRequest = dmx.stripe.paymentRequest({
        country: this.props.country,
        currency: this.props.currency,
        total: {
          label: this.props.label,
          amount: this.props.amount,
          pending: this.props.pending,
        },
        displayItems: this.props.items,
        requestPayerName: this.props.requestName,
        requestPayerEmail: this.props.requestEmail,
        requestPayerPhone: this.props.requestPhone,
        requestShipping: this.props.requestShipping,
        shippingOptions: this.props.shippingOptions,
      });

      this._paymentRequest.on('token', (event) => {
        this.set('token', event.token);
        this.dispatchEvent('token');
        this.complete = event.complete;
      });

      this._paymentRequest.on('source', (event) => {
        this.set('source', event.source);
        this.dispatchEvent('source');
        this.complete = event.complete;
      });

      this._paymentRequest.on('paymentmethod', (event) => {
        this.set('paymentMethod', event.paymentMethod);
        this.dispatchEvent('paymentmethod');
        this.complete = event.complete;
      });

      this._paymentRequest.on('shippingoptionchange', (event) => {
        this.set('shippingOption', event.shippingOption);
        this.dispatchEvent('shippingoptionchange');
        this.updateWith = event.updateWith;
      });

      this._paymentRequest.on('shippingaddresschange', (event) => {
        this.set('shippingAddress', event.shippingAddress);
        this.dispatchEvent('shippingaddresschange');
        this.updateWith = event.updateWith;
      });

      this._paymentRequest.on('cancel', this.dispatchEvent.bind(this, 'cancel'));

      this._element = dmx.stripe.elements.create('paymentRequestButton', {
        paymentRequest: this._paymentRequest,
        classes: theme.classes,
        style: theme.style,
      });

      this._paymentRequest.canMakePayment().then((result) => {
        if (result) {
          this.element.mount(node);
          this.element.on('click', this.dispatchEvent.bind(this, 'click'));
        }
      });
    });
  },

  performUpdate (updatedProps) {
    if (this._paymentRequest) {
      if (updatedProps.has('country')) {
        this._paymentRequest.update({ country: this.props.country });
      }

      if (updatedProps.has('currency')) {
        this._paymentRequest.update({ currency: this.props.currency });
      }

      if (updatedProps.has('label') || updatedProps.has('amount') || updatedProps.has('pending')) {
        this._paymentRequest.update({
          total: {
            label: this.props.label,
            amount: this.props.amount,
            pending: this.props.pending,
          },
        });
      }

      if (updatedProps.has('items')) {
        this._paymentRequest.update({ displayItems: this.props.items });
      }

      if (updatedProps.has('requestName')) {
        this._paymentRequest.update({ requestPayerName: this.props.requestName });
      }

      if (updatedProps.has('requestEmail')) {
        this._paymentRequest.update({ requestPayerEmail: this.props.requestEmail });
      }

      if (updatedProps.has('requestPhone')) {
        this._paymentRequest.update({ requestPayerPhone: this.props.requestPhone });
      }

      if (updatedProps.has('requestShipping')) {
        this._paymentRequest.update({ requestShipping: this.props.requestShipping });
      }

      if (updatedProps.has('shippingOptions')) {
        this._paymentRequest.update({ shippingOptions: this.props.shippingOptions });
      }
    }
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

});
