dmx.Component('stripe-payment-method', {

  ui: {
    deprecated: true,
  },

  initialData: {
    selected: 'card',
  },

  attributes: {
    paymentMethods: {
      type: Array,
      default: ['card'],
      enum: [
        'afterpay_clearpay',
        'alipay',
        'au_becs_debit',
        'bancontact',
        'card',
        'eps',
        'fpx',
        'giropay',
        'grabpay',
        'ideal',
        'oxxo',
        'p24',
        'sepa_debit',
        'sofort',
      ],
    },

    selected: {
      type: String,
      default: 'card',
      enum: [
        'afterpay_clearpay',
        'alipay',
        'au_becs_debit',
        'bancontact',
        'card',
        'eps',
        'fpx',
        'giropay',
        'grabpay',
        'ideal',
        'oxxo',
        'p24',
        'sepa_debit',
        'sofort',
      ],
    },

    display: {
      type: String,
      default: 'select',
      enum: ['select', 'pills', 'tabs', 'buttons'], // bs5 only
    },

    hidePostalCode: {
      // for card element
      type: Boolean,
      default: false,
    },

    iconStyle: {
      // for card, iban, aubank element
      type: String,
      default: 'default',
      enum: ['solid', 'default'],
    },

    hideIcon: {
      // for card, iban, ideal, p24, eps, aubank element
      type: Boolean,
      default: false,
    },

    hideErrors: {
      type: Boolean,
      default: false,
    },

    accountHolderType: {
      // for fpx element
      type: String,
      default: 'individual',
      enum: ['company', 'individual'],
    },

    // Following are for localization
    localeAfterpayClearpay: {
      type: String,
      default: 'Afterpay',
    },

    localeAlipay: {
      type: String,
      default: 'Alipay',
    },

    localeAuBecsDebit: {
      type: String,
      default: 'BECS debit',
    },

    localeBancontact: {
      type: String,
      default: 'Bancontact',
    },

    localeCard: {
      type: String,
      default: 'Card',
    },

    localeEps: {
      type: String,
      default: 'EPS',
    },

    localeFpx: {
      type: String,
      default: 'FPX',
    },

    localeGiropay: {
      type: String,
      default: 'Giropay',
    },

    localeGrabpay: {
      type: String,
      default: 'GrabPay',
    },

    localeIdeal: {
      type: String,
      default: 'iDEAL',
    },

    localeOxxo: {
      type: String,
      default: 'OXXO',
    },

    localeP24: {
      type: String,
      default: 'Przelewy24',
    },

    localeSepaDebit: {
      type: String,
      default: 'SEPA debit',
    },

    localeSofort: {
      type: String,
      default: 'Sofort',
    },

    localeFpxConditions: {
      type: String,
      default: 'By Proceding, you agree to FPX’s Terms and Conditions.',
    },

    localeSepaDebitMandate: {
      type: String,
      default:
        'By providing your payment information and confirming this payment, you authorise (A) {{company}} and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.',
    },

    localeAuBecsDebitMandate: {
      type: String,
      default:
        'By providing your bank account details and confirming this payment, you agree to this Direct Debit Request and the Direct Debit Request service agreement, Direct Debit Request service agreement, and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Stripe”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of (the “Merchant”) for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above.',
    },

    localeCompany: {
      type: String,
      default: 'Us',
    },
  },

  methods: {
    select (method) {
      this._select(method);
    },
  },

  render (node) {
    this._render();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('display') || updatedProps.has('paymentMethods')) {
      this._render();
    } else if (updatedProps.has('selected')) {
      this._select(this.props.selected);
    }
  },

  _bootstrapVersion () {
    return window.bootstrap && bootstrap.Alert ? parseInt(bootstrap.Alert.VERSION) : 0;
  },

  _select (method) {
    if (this.props.paymentMethods.includes(method)) {
      switch (this.props.display) {
        case 'pills':
        case 'tabs':
          break;
        case 'buttons':
          let radio = this.$node.querySelector('input[value=' + method + ']');
          if (radio) radio.click();
          break;
        default:
          let select = this.$node.querySelector('select');
          if (select) select.value = method;
          break;
      }
    }
  },

  _render () {
    let template = '';
    let bootstrap = this._bootstrapVersion();

    if (typeof this.props.paymentMethods == 'string') {
      this.props.paymentMethods = this.props.paymentMethods.split(',');
      return;
    }

    if (!Array.isArray(this.props.paymentMethods)) {
      return;
    }

    switch (this.props.display) {
      case 'pills':
      case 'tabs':
        template += '<ul class="nav nav-' + this.props.display + '">';
        this.props.paymentMethods.forEach((method) => {
          template += '<li class="nav-item">';
          if (bootstrap == 4) {
            template +=
              '<a value="' +
              method +
              '" class="nav-link' +
              (method == this.props.selected ? ' active' : '') +
              '" data-toggle="tab" data-target="#payment-method-' +
              method +
              '">' +
              this.props['locale' + this._format(method)] +
              '</a>';
          } else {
            template +=
              '<button value="' +
              method +
              '" class="nav-link' +
              (method == this.props.selected ? ' active' : '') +
              '" data-bs-toggle="tab" data-bs-target="#payment-method-' +
              method +
              '">' +
              this.props['locale' + this._format(method)] +
              '</button>';
          }
          template += '</li>';
        });
        template += '</ul>';
        break;

      case 'buttons':
        if (bootstrap == 4) {
          template += '<div class="btn-group btn-group-toggle" data-toggle="buttons" role="group">';
          this.props.paymentMethods.forEach((method) => {
            template += '<label class="btn btn-secondary' + (method == this.props.selected ? ' active' : '') + '">';
            template +=
              '<input type="radio" name="paymentmethod" value="' +
              method +
              '"' +
              (method == this.props.selected ? ' checked' : '') +
              '>';
            template += this.props['locale' + this._format(method)];
            template += '</label>';
          });
          template += '</div>';
        } else {
          template += '<div class="btn-group" role="group">';
          this.props['payment-methods'].forEach((method) => {
            template +=
              '<input type="radio" class="btn-check" name="paymentmethod" value="' +
              method +
              '" id="paymentmethod_' +
              method +
              '" autocomplete="off"' +
              (method == this.props.selected ? ' checked' : '') +
              '>';
            template +=
              '<label class="btn btn-outline-primary" for="paymentmethod_' +
              method +
              '">' +
              this.props['locale' + this._format(method)] +
              '</label>';
          });
          template += '</div>';
        }
        break;

      default:
        if (bootstrap == 4) {
          template += '<select class="form-control">';
        } else {
          template += '<select class="form-select">';
        }
        this.props.paymentMethods.forEach((method) => {
          template +=
            '<option value="' +
            method +
            '"' +
            (method == this.props.selected ? ' selected' : '') +
            '>' +
            this.props['locale' + this._format(method)] +
            '</option>';
        });
        template += '</select>';
        template += '</div>';
        break;
    }

    template += '<div class="tab-content my-3">';
    this.props.paymentMethods.forEach((method) => {
      template +=
        '<div id="payment-method-' +
        method +
        '" class="tab-pane' +
        (method == this.props.selected ? ' active' : '') +
        '">';
      template += '<div id="' + method + '-element"></div>';
      template += '<div id="' + method + '-error" class="invalid-feedback"></div>';
      template += '<div id="' + method + '-mandate"></div>';
      template += '</div>';
    });
    template += '</div>';

    this.$node.innerHTML = template;

    switch (this.props.display) {
      case 'pills':
      case 'tabs':
        if (bootstrap == 4) {
          $('a[data-toggle="tab"]', this.$node).on('shown.bs.tab', (event) => {
            this.set('selected', event.target.getAttribute('value'));
          });
        } else {
          var tabs = this.$node.querySelector('.nav');
          tabs.addEventListener('shown.bs.tab', (event) => {
            this.set('selected', event.target.getAttribute('value'));
          });
        }
        break;
      case 'buttons':
        if (bootstrap == 4) {
          var radios = this.$node.querySelectorAll('input');
          for (var i = 0; i < radios.length; i++) {
            radios[i].onchange = (event) => {
              var selected = this.$node.querySelector('input:checked');
              this.set('selected', selected.value);
              this.$node.querySelector('.tab-content .active').classList.remove('active');
              document.getElementById('payment-method-' + selected.value).classList.add('active');
            };
          }
        } else {
          var radios = this.$node.querySelectorAll('input');
          for (var i = 0; i < radios.length; i++) {
            radios[i].onchange = (event) => {
              var selected = this.$node.querySelector('input:checked');
              this.set('selected', selected.value);
              this.$node.querySelector('.active').classList.remove('active');
              document.getElementById('payment-method-' + selected.value).classList.add('active');
            };
          }
        }
        break;
      default:
        var select = this.$node.querySelector('select');
        select.onchange = (event) => {
          this.set('selected', select.value);
          this.$node.querySelector('.active').classList.remove('active');
          document.getElementById('payment-method-' + select.value).classList.add('active');
        };
        break;
    }

    this.props.paymentMethods.forEach((method) => {
      var classes = dmx.stripe.theme.bootstrap.classes;
      var style = dmx.stripe.theme.bootstrap.style;

      switch (method) {
        case 'card':
          var card = dmx.stripe.elements.getElement('card');
          if (!card) {
            card = dmx.stripe.elements.create('card', {
              classes: classes,
              style: style,
              hidePostalCode: this.props.hidePostalCode,
              iconStyle: this.props.iconStyle,
              hideIcon: this.props.hideIcon,
            });
            card.mount('#' + method + '-element');
            card.on('change', (event) => {
              if (!this.props.hideErrors) {
                var displayError = document.getElementById(method + '-error');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              }
            });
          }
          break;

        case 'au_becs_debit':
          var auBankAccount = dmx.stripe.elements.getElement('auBankAccount');
          if (!auBankAccount) {
            auBankAccount = dmx.stripe.elements.create('auBankAccount', {
              classes: classes,
              style: style,
              iconStyle: this.props.iconStyle,
              hideIcon: this.props.hideIcon,
            });
            auBankAccount.mount('#' + method + '-element');
            auBankAccount.on('change', (event) => {
              if (!this.props.hideErrors) {
                var displayError = document.getElementById(method + '-error');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              }
            });
          }
          document.getElementById(method + '-mandate').textContent = this.props[
            'locale' + this._format(method) + 'Mandate'
          ].replace('{{company}}', this.props.localeCompany);
          document.getElementById(method + '-mandate').className = 'alert alert-info mt-3';
          break;

        case 'sepa_debit':
          var iban = dmx.stripe.elements.getElement('iban');
          if (!iban) {
            iban = dmx.stripe.elements.create('iban', {
              classes: classes,
              style: style,
              supportedCountries: ['SEPA'],
              iconStyle: this.props.iconStyle,
              hideIcon: this.props.hideIcon,
            });
            iban.mount('#' + method + '-element');
            iban.on('change', (event) => {
              if (!this.props.hideErrors) {
                var displayError = document.getElementById(method + '-error');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              }
            });
          }
          document.getElementById(method + '-mandate').textContent = this.props[
            'locale-' + method + '-mandate'
          ].replace('{{company}}', this.props.localeCompany);
          document.getElementById(method + '-mandate').className = 'alert alert-info mt-3';
          break;

        case 'ideal':
          var idealBank = dmx.stripe.elements.getElement('idealBank');
          if (!idealBank) {
            idealBank = dmx.stripe.elements.create('idealBank', {
              classes: classes,
              style: style,
              hideIcon: this.props.hideIcon,
            });
            idealBank.mount('#' + method + '-element');
            idealBank.on('change', (event) => {
              if (!this.props.hideErrors) {
                var displayError = document.getElementById(method + '-error');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              }
            });
          }
          break;

        case 'fpx':
          var fpxBank = dmx.stripe.elements.getElement('fpxBank');
          if (!fpxBank) {
            fpxBank = dmx.stripe.elements.create('fpxBank', {
              classes: classes,
              style: style,
              accountHolderType: this.props.accountHolderType,
            });
            fpxBank.mount('#' + method + '-element');
            fpxBank.on('change', (event) => {
              if (!this.props.hideErrors) {
                var displayError = document.getElementById(method + '-error');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              }
            });
          }
          document.getElementById(method + '-mandate').textContent = this.props[
            'locale' + this._format(method) + 'Conditions'
          ].replace('{{company}}', this.props.localeCompany);
          document.getElementById(method + '-mandate').className = 'alert alert-info mt-3';
          break;

        case 'p24':
          var p24Bank = dmx.stripe.elements.getElement('p24Bank');
          if (!p24Bank) {
            p24Bank = dmx.stripe.elements.create('p24Bank', {
              classes: classes,
              style: style,
              hideIcon: this.props.hideIcon,
            });
            p24Bank.mount('#' + method + '-element');
            p24Bank.on('change', (event) => {
              if (!this.props.hideErrors) {
                var displayError = document.getElementById(method + '-error');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              }
            });
          }
          break;

        case 'eps':
          var epsBank = dmx.stripe.elements.getElement('epsBank');
          if (!epsBank) {
            epsBank = dmx.stripe.elements.create('epsBank', {
              classes: classes,
              style: style,
              hideIcon: this.props.hideIcon,
            });
            epsBank.mount('#' + method + '-element');
            epsBank.on('change', (event) => {
              if (!this.props.hideErrors) {
                var displayError = document.getElementById(method + '-error');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              }
            });
          }
          break;
      }
    });
  },

  _format (method) {
    return method[0].toUpperCase() + method.slice(1).replace(/_./g, (m, c) => c.toUpperCase());
  }

});
