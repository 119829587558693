dmx.Component('stripe-payment', {

  initialData: {
    complete: false,
    empty: false,
    collapsed: false,
    value: undefined,
    error: undefined,
    isLoading: false,
  },

  attributes: {
    layout: {
      type: String,
      default: 'tabs',
      enum: ['accordion', 'tabs'],
    },

    billingDetails: {
      type: String,
      default: 'auto',
      enum: ['auto', 'never'],
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    blur () {
      if (this._element) {
        this._element.blur();
      }
    },

    clear () {
      if (this._element) {
        this._element.clear();
      }
    },

    focus () {
      if (this._element) {
        this._element.focus();
      }
    },

    fetchUpdates () {
      if (this._element) {
        this.set('isLoading', true);
        return this._element.fetchUpdates().then((result) => {
          this.set('isLoading', false);
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          }
        });
      }
    },

    collapse () {
      if (this._element) {
        this._element.collapse();
      }
    },

    confirmPayment (options) {
      if (this._element) {
        // options.confirmParams.return_url is required
        this.set('isLoading', true);
        return dmx.stripe.instance.confirmPayment({
          elements: dmx.stripe.elements,
          ...options,
        }).then((result) => {
          this.set('isLoading', false);
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          }
          return result;
        });
      }
    },

    confirmSetup (options) {
      if (this._element) {
        // options.confirmParams.return_url is required
        this.set('isLoading', true);
        return dmx.stripe.instance.confirmSetup({
          elements: dmx.stripe.elements,
          ...options,
        }).then((result) => {
          this.set('isLoading', false);
          if (result.error) {
            this.set('error', result.error);
            this.dispatchEvent('error');
          }
          return result;
        });
      }
    },
  },

  events: {
    change: Event,
    ready: Event,
    focus: Event,
    blur: Event,
    escape: Event,
    error: Event,
  },

  render (node) {
    dmx.stripe.ready(() => {
      this._element = dmx.stripe.elements.create('payment', {
        layout: this.props.layout,
        fields: {
          billingDetails: this.props.billingDetails
        },
        readOnly: this.props.readonly
      });

      this._element.mount(node);

      this._element.on('change', this._changeHandler.bind(this));
      this._element.on('loaderror', this._loadErrorHandler.bind(this));
      this._element.on('change', this.dispatchEvent.bind(this, 'change'));
      this._element.on('ready', this.dispatchEvent.bind(this, 'ready'));
      this._element.on('focus', this.dispatchEvent.bind(this, 'focus'));
      this._element.on('blur', this.dispatchEvent.bind(this, 'blur'));
      this._element.on('escape', this.dispatchEvent.bind(this, 'escape'));
    });
  },

  performUpdate (updatedProps) {
    if (this._element) {
      if (updatedProps.has('layout')) {
        this._element.update({ layout: this.props.layout });
      }

      if (updatedProps.has('billingDetails')) {
        this._element.update({ fields: { billingDetails: this.props.billingDetails } });
      }

      if (updatedProps.has('readonly')) {
        this._element.update({ readOnly: this.props.readonly });
      }
    }
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

  _changeHandler (event) {
    this.set(event);
  },

  _loadErrorHandler (event) {
    this.set(event);
  },

});