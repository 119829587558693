dmx.Component('stripe', {

  ui: {
    deprecated: true,
  },

  initialData: {
    data: null,
    lastError: null,
  },

  attributes: {
    key: {
      type: String,
      default: null,
    },

    account: {
      type: String,
      default: null,
    },

    locale: {
      type: String,
      default: 'auto',
    },

    loader: {
      type: String,
      default: 'auto',
      enum: ['auto', 'always', 'never'],
    },

    lineItems: {
      type: [String, Array],
      default: null,
    },

    mode: {
      type: String,
      default: 'payment',
      enum: ['payment', 'subscription'],
    },

    successUrl: {
      type: String,
      default: null,
    },

    cancelUrl: {
      type: String,
      default: null,
    },

    sessionUrl: {
      type: String,
      default: null,
    },

    paymentUrl: {
      type: String,
      default: null,
    },

    setupUrl: {
      type: String,
      default: null,
    },

    clientSecret: {
      type: String,
      default: undefined,
    },

    theme: {
      type: String,
      default: 'stripe',
      enum: ['stripe', 'night', 'flat'],
    },
  },

  methods: {
    checkout (data) {
      if (this.props.sessionUrl) {
        let url = this.props.sessionUrl;
        let headers = { 'Content-Type': 'application/json' };

        if (window.WebviewProxy) {
          url = window.WebviewProxy.convertProxyUrl(url);
        }

        const csrf_token = document.querySelector('meta[name="csrf-token"]');
        if (csrf_token) {
          headers['X-CSRF-Token'] = csrf_token.content;
        }

        return fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify({ lineItems: this.props.lineItems || [], ...data }),
        }).then((response) => {
          if (response.ok) {
            return response.json().then((data) => {
              if (!data.id) {
                if (data.error) {
                  this._error(data.error);
                } else {
                  this._error('Id is missing in session response.');
                }
                return;
              }

              return dmx.stripe.instance
                .redirectToCheckout({ sessionId: data.id })
                .then((result) => {
                  if (result.error) {
                    this._error(result.error);
                  }
                });
            });
          } else {
            this._error('An error occurred during the transaction.');
          }
        }).catch((e) => {
          this._error(e.message);
        });
      }

      const checkoutOptions = {
        lineItems: this.props.lineItems,
        mode: this.props.mode,
        successUrl: this.props.successUrl,
        cancelUrl: this.props.cancelUrl,
      };

      if (!checkoutOptions.successUrl) {
        return this._error('successUrl is required.');
      }

      if (!checkoutOptions.cancelUrl) {
        return this._error('cancelUrl is required.');
      }

      if (typeof checkoutOptions.lineItems == 'string') {
        checkoutOptions.lineItems = [checkoutOptions.lineItems];
      }

      if (!Array.isArray(checkoutOptions.lineItems)) {
        return this._error('Invalid line items.');
      }

      checkoutOptions.lineItems = checkoutOptions.lineItems.map((item) => {
        if (typeof item == 'string') {
          return { price: item, quantity: 1 };
        }

        return { price: item.price || item.sku || item.plan, quantity: item.quantity || 1 };
      });

      checkoutOptions.successUrl = this._fixUrl(checkoutOptions.successUrl);
      checkoutOptions.cancelUrl = this._fixUrl(checkoutOptions.cancelUrl);

      return dmx.stripe.instance.redirectToCheckout(checkoutOptions).then((result) => {
        if (result.error) {
          this._error(result.error);
        }
      });
    },

    createPayment (data) {
      if (this.props.paymentUrl) {
        let url = this.props.paymentUrl;
        let headers = { 'Content-Type': 'application/json' };

        if (window.WebviewProxy) {
          url = window.WebviewProxy.convertProxyUrl(url);
        }

        const csrf_token = document.querySelector('meta[name="csrf-token"]');
        if (csrf_token) {
          headers['X-CSRF-Token'] = csrf_token.content;
        }

        return fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify({ lineItems: this.props.lineItems || [], ...data }),
        }).then((response) => {
          if (response.ok) {
            return response.json().then((data) => {
              if (data.client_secret) {
                dmx.stripe.client_secret = data.client_secret;
              }
              this.set('data', data);
              this.dispatchEvent('done');
              this.dispatchEvent('payment_created');
            });
          } else {
            this._error('An error occurred during the transaction.');
          }
        }).catch((e) => {
          this._error(e.message);
        });
      }
    },

    createSetup (data) {
      if (this.props.setupUrl) {
        let url = this.props.setupUrl;
        let headers = { 'Content-Type': 'application/json' };

        if (window.WebviewProxy) {
          url = window.WebviewProxy.convertProxyUrl(url);
        }

        const csrf_token = document.querySelector('meta[name="csrf-token"]');
        if (csrf_token) {
          headers['X-CSRF-Token'] = csrf_token.content;
        }

        return fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify({ lineItems: this.props.lineItems || [], ...data }),
        }).then((response) => {
          if (response.ok) {
            return response.json().then((data) => {
              if (data.client_secret) {
                dmx.stripe.client_secret = data.client_secret;
              }
              this.set('data', data);
              this.dispatchEvent('done');
              this.dispatchEvent('setup_created');
            });
          } else {
            this._error('An error occurred during the transaction.');
          }
        }).catch((e) => {
          this._error(e.message);
        });
      }
    },
  },

  events: {
    done: Event,
    error: Event,
    ready: Event,
    payment_created: Event,
    setup_created: Event,
  },

  render: false,

  init () {
    if (this.props.key) {
      this._setup();
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('key')) {
      this._setup();
    }
  },

  _setup () {
    if (this.$node.hasAttribute('dmx-bind:client-secret') && !this.props.clientSecret) {
      // Wait for client secret to be set
      return;
    }

    try {
      dmx.stripe.locale = this.props.locale;

      const options = {
        locale: this.props.locale,
      };

      if (this.props.account) {
        options.stripeAccount = this.props.account;
      }

      dmx.stripe.instance = Stripe(this.props.key, options);
      dmx.stripe.elements = dmx.stripe.instance.elements({
        clientSecret: this.props.clientSecret,
        locale: this.props.locale,
        loader: this.props.loader,
        appearance: {
          theme: this.props.theme,
        },
      });

      if (dmx.stripe.wait.size) {
        for (const cb of dmx.stripe.wait) {
          cb(dmx.stripe.instance);
        }
        dmx.stripe.wait.clear();
      }

      this.dispatchEvent('ready');
    } catch (err) {
      console.error(err);
    }
  },

  _fixUrl (url) {
    var link = document.createElement('a');
    link.setAttribute('href', url);
    return link.href;
  },

  _error (msg) {
    console.error(msg);
    this.set('lastError', msg);
    this.dispatchEvent('error');
  },
});
