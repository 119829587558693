dmx.Component('stripe-link-authentication', {

  initialData: {
    complete: false,
    empty: true,
    value: {
      email: '',
    },
    error: undefined,
  },

  attributes: {
    email: {
      type: String,
      default: '',
    },
  },

  methods: {
    blur () {
      if (this._element) {
        this._element.blur();
      }
    },

    clear () {
      if (this._element) {
        this._element.clear();
      }
    },

    focus () {
      if (this._element) {
        this._element.focus();
      }
    },
  },

  render (node) {
    dmx.stripe.ready(() => {
      this._element = dmx.stripe.elements.create('linkAuthentication', {
        defaultValues: {
          email: this.props.email,
        },
      });

      this._element.mount(node);

      this._element.on('change', this._changeHandler.bind(this));
      this._element.on('loaderror', this._loadErrorHandler.bind(this));
      this._element.on('change', this.dispatchEvent.bind(this, 'change'));
      this._element.on('ready', this.dispatchEvent.bind(this, 'ready'));
      this._element.on('focus', this.dispatchEvent.bind(this, 'focus'));
      this._element.on('blur', this.dispatchEvent.bind(this, 'blur'));
      this._element.on('escape', this.dispatchEvent.bind(this, 'escape'));
    });
  },

  performUpdate (updatedProps) {
    if (this._element) {
      if (updatedProps.has('email')) {
        this._element.update({ defaultValues: { email: this.props.email } });
      }
    }
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

  _changeHandler (event) {
    this.set(event);
  },

  _loadErrorHandler (event) {
    this.set(event);
  },

});