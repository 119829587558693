dmx.Component('stripe-address', {

  initialData: {
    complete: false,
    empty: true,
    isNewAddress: false,
    value: undefined,
    error: undefined,
  },

  attributes: {
    mode: {
      type: String,
      default: 'shipping',
      enum: ['shipping', 'billing'],
    },

    autocomplete: {
      type: String,
      default: 'automatic',
      enum: ['automatic', 'disabled', 'google_maps_api'],
    },

    googleApiKey: {
      type: String,
      default: null,
    },

    blockPoBox: {
      type: Boolean,
      default: false,
    },

    displayName: {
      type: String,
      default: 'full',
      enum: ['full', 'split', 'organization'],
    },
  },

  methods: {
    blur () {
      if (this._element) {
        this._element.blur();
      }
    },

    clear () {
      if (this._element) {
        this._element.clear();
      }
    },

    focus () {
      if (this._element) {
        this._element.focus();
      }
    },
  },

  render (node) {
    dmx.stripe.ready(() => {
      const options = {
        mode: this.props.mode,
        autocomplete: {
          mode: this.props.autocomplete,
        },
        blockPoBox: this.props.blockPoBox,
        display: {
          name: this.props.displayName,
        },
      };

      if (this.props.googleApiKey) {
        options.autocomplete.apiKey = this.props.googleApiKey;
      }

      this._element = dmx.stripe.elements.create('address', options);

      this._element.mount(node);

      this._element.on('change', this._changeHandler.bind(this));
      this._element.on('loaderror', this._loadErrorHandler.bind(this));
      this._element.on('change', this.dispatchEvent.bind(this, 'change'));
      this._element.on('ready', this.dispatchEvent.bind(this, 'ready'));
      this._element.on('focus', this.dispatchEvent.bind(this, 'focus'));
      this._element.on('blur', this.dispatchEvent.bind(this, 'blur'));
      this._element.on('escape', this.dispatchEvent.bind(this, 'escape'));
    });
  },

  performUpdate (updatedProps) {
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

  _changeHandler (event) {
    this.set(event);
  },

  _loadErrorHandler (event) {
    this.set(event);
  },

});