dmx.Component('stripe-express-checkout', {

  initialData: {
    error: undefined,
  },

  attributes: {
    buttonHeight: {
      type: Number,
      default: 44,
    },

    buttonTypeApplePay: {
      type: String,
      default: 'plain',
      enum: ['add-money', 'book', 'buy', 'check-out', 'continue', 'contribute', 'donate', 'order', 'plain', 'reload', 'rent', 'subscribe', 'support', 'tip', 'top-up'],
    },

    buttonTypeGooglePay: {
      type: String,
      default: 'buy',
      enum: ['book', 'buy', 'checkout', 'donate', 'order', 'pay', 'plain', 'subscribe'],
    },

    buttonTypePaypal: {
      type: String,
      default: 'paypal',
      enum: ['paypal', 'checkout', 'buynow', 'pay'],
    },

    buttonThemeApplePay: {
      type: String,
      default: 'default',
      enum: ['default', 'black', 'white', 'white-outline'],
    },

    buttonThemeGooglePay: {
      type: String,
      default: 'default',
      enum: ['default', 'black', 'white'],
    },

    buttonThemePaypal: {
      type: String,
      default: 'default',
      enum: ['default', 'gold', 'blue', 'silver', 'white', 'black'],
    },

    maxColumns: {
      type: Number,
      default: 0,
    },

    maxRows: {
      type: Number,
      default: 0,
    },

    overflow: {
      type: String,
      default: 'auto',
      enum: ['auto', 'never'],
    },
  },

  methods: {
    blur () {
      if (this._element) {
        this._element.blur();
      }
    },

    clear () {
      if (this._element) {
        this._element.clear();
      }
    },

    focus () {
      if (this._element) {
        this._element.focus();
      }
    },
  },

  events: {
    confirm: Event,
    cancel: Event,
  },

  render (node) {
    dmx.stripe.ready(() => {
      const options = {
        buttonHeight: this.props.buttonHeight,
        buttonTheme: {},
        buttonType: {
          applePay: this.props.buttonTypeApplePay,
          googlePay: this.props.buttonTypeGooglePay,
          paypal: this.props.buttonTypePaypal,
        },
        layout: {
          maxColumns: this.props.maxColumns,
          maxRows: this.props.maxRows,
          overflow: this.props.overflow,
        },
      };

      if (this.props.buttonThemeApplePay != 'default') options.buttonTheme.applePay = this.props.buttonThemeApplePay;
      if (this.props.buttonThemeGooglePay != 'default') options.buttonTheme.googlePay = this.props.buttonThemeGooglePay;
      if (this.props.buttonThemePaypal != 'default') options.buttonTheme.paypal = this.props.buttonThemePaypal;

      this._element = dmx.stripe.elements.create('expressCheckout', options);

      this._element.mount(node);

      this._element.on('confirm', this._confirmHandler.bind(this));
      this._element.on('cancel', this._cancelHandler.bind(this));
    });
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('maxColumns') || updatedProps.has('maxRows') || updatedProps.has('overflow')) {
      this._element.update({
        layout: {
          maxColumns: this.props.maxColumns,
          maxRows: this.props.maxRows,
          overflow: this.props.overflow,
        },
      });
    }
  },

  destroy () {
    if (this._element) {
      this._element.destroy();
    }
  },

  _confirmHandler (event) {
    this.dispatchEvent('confirm', { event });
  },

  _cancelHandler (event) {
    this.dispatchEvent('cancel', { event });
  },

});