dmx.Component('stripe-embedded-checkout', {

  attributes: {
    key: {
      type: String,
      default: null,
    },
    
    sessionUrl: {
      type: String,
      default: null,
    },

    lineItems: {
      type: Array,
      default: [],
    },
  },

  render: false,

  init () {
    this._init();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('key')) {
      this._init();
    } else if (updatedProps.has('sessionUrl') || updatedProps.has('lineItems')) {
      this._render();
    }
  },

  _init () {
    if (this.props.key) {
      this._stripe = Stripe(this.props.key);
      this._render();
    }
  },

  _render () {
    if (!this._stripe || !this.props.sessionUrl) return;

    const fetchClientSecret = () => fetch(this.props.sessionUrl, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ lineItems: this.props.lineItems }),
    }).then((response) => response.json()).then((data) => data.client_secret);
    
    this._stripe.initEmbeddedCheckout({ fetchClientSecret }).then((checkout) => {
      checkout.mount(this.$node);
    });
  },

});